import React, { useState } from "react"
import {
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFlyout,
  EuiFlyoutBody,
  EuiFlyoutFooter,
  EuiFlyoutHeader,
  EuiTitle
} from "@elastic/eui"
import EntityForm from "./EntityForm"
import { Resource } from "../Resource"
import { EntityDescription } from "../descriptions"


export function useEntityFlyout() {
  const [isOpen, setOpen] = useState(false)
  const close = () => setOpen(false)
  const open = () => setOpen(true)
  return {isOpen, setOpen, open, close}
}

interface EntityFlyoutProps {
  headerTitleAction: string
  entityDesc: EntityDescription
  resource: Resource
  okButton: React.ReactElement
  onClose: (resource: Resource) => void
  isOpen: boolean
}

const EntityFlyout = (props: EntityFlyoutProps) => {
  const { entityDesc, resource, isOpen, okButton, onClose, headerTitleAction } = props

  return (
    <>
      {
        isOpen &&
        <EuiFlyout onClose={() => onClose(resource)} size={"s"}>

          {/*entity header*/}
          <EuiFlyoutHeader hasBorder>
            <EuiFlexGroup direction={"row"} alignItems={"baseline"}>

              <EuiFlexItem>
                <EuiTitle>
                  <h2 id="flyoutLargeTitle">{headerTitleAction} {entityDesc.name}</h2>
                </EuiTitle>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlyoutHeader>

          {/* entity fields */}
          <EuiFlyoutBody>
            <EntityForm entityDesc={entityDesc} resource={resource} />
          </EuiFlyoutBody>

          {/* entity actions */}
          <EuiFlyoutFooter>
            <EuiFlexGroup>
              <EuiFlexItem>
                <EuiButton fullWidth onClick={() => onClose(resource)}>Cancel</EuiButton>
              </EuiFlexItem>
              <EuiFlexItem>
                {okButton}
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlyoutFooter>
        </EuiFlyout>
      }
    </>
  )
}

export default EntityFlyout
