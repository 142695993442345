import React from "react"
import "./index.css"
import * as serviceWorker from "./serviceWorker"
import "normalize.css"
import "@elastic/eui/dist/eui_theme_light.css"
import Application from "./app/Application"
import { BrowserRouter } from "react-router-dom"
import { createRoot } from "react-dom/client"
import EntityDescriptionDesc from "./app/entities/data-model/EntityDesc"
import { EntityDescriptionReferenceFieldsField } from "./app/entities/data-model/ReferenceFieldDescription"
import { EntityDescriptionEntitiesCollectionFieldsField } from "./app/entities/data-model/EntitiesCollectionField"

EntityDescriptionDesc.entitiesCollectionFields.push(EntityDescriptionEntitiesCollectionFieldsField)
EntityDescriptionDesc.entitiesCollectionFields.push(EntityDescriptionReferenceFieldsField)

const container = document.getElementById('root');
const root = createRoot(container!); // createRoot(container!) if you use TypeScript
root.render(
  <BrowserRouter>
    <Application/>
  </BrowserRouter>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
