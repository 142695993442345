import { Field } from "./descriptions"
import newId from "./newId"
import { camelCase } from "change-case"

export class Resource implements Record<string, any> {
  constructor(public id: string = newId()) {
  }
}

export function setResourceValue<T extends Resource>(resource: T, fieldDesc: Field, value?: any): void {
  (resource as Record<string, any>)[camelCase(fieldDesc.name)] = value
}

export function getResourceValue<T extends Resource>(resource: T, fieldDesc: Field): any {
  return (resource as Record<string, any>)[camelCase(fieldDesc.name)]
}
