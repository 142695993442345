import { EntityDescription, NUMBER, PrimitiveField, STRING } from "../../../resources/descriptions"

export const EnumValueIdField = new PrimitiveField("Ordinal", NUMBER)
export const EnumValueKeyField = new PrimitiveField("Key", STRING)
export const EnumValueNameField = new PrimitiveField("Name", STRING)

const EnumValueDescription = new EntityDescription("Enum value",
  [
    EnumValueIdField,
    EnumValueKeyField,
    EnumValueNameField
  ], [], [], [])

export default EnumValueDescription
