import { EntitiesCollectionField, EntityDescription, PrimitiveField, STRING } from "../../../resources/descriptions"
import PrimitiveFieldDescription from "./PrimitiveFieldDescription"
import EnumFieldDescription from "./EnumFieldDescription"

export const EntityDescriptionNameField = new PrimitiveField("Name", STRING)
export const EntityDescriptionPrimitiveFieldsField = new EntitiesCollectionField("Primitive fields", PrimitiveFieldDescription)
export const EntityDescriptionEnumFieldsField = new EntitiesCollectionField("Enum fields", EnumFieldDescription)

const EntityDescriptionDesc = new EntityDescription("Entity",
  [
    EntityDescriptionNameField
  ],
  [
    EntityDescriptionPrimitiveFieldsField,
    EntityDescriptionEnumFieldsField
  ],
  [],
  []
)

export default EntityDescriptionDesc
