import { Resource } from "../Resource"
import { EntityDescription } from "../descriptions"
import { paramCase } from "change-case"

export type RequestType = "save" | "list" | "delete" | string

interface Request {
  type: RequestType
  body?: any
}


export class Api<I extends Resource> {
  constructor(
    public url: string,
    public entityDescription: EntityDescription) {
  }

  create<I extends Resource>(instance: I): Promise<I> {
    return this.request({
      type: paramCase(this.entityDescription.name),
      body: instance
    })
  }

  update<I extends Resource>(instance: I): Promise<I> {
    const url =
      this.url + "/" +
      paramCase(this.entityDescription.name) + "/" +
      instance.id

    const body = this.jsonBody(instance)

    const init = {
      method: "PUT",
      body
    } as RequestInit

    return fetch(url, init).then(r => r.json())
  }

  list(desc: EntityDescription): Promise<Resource[]> {
    return this.queryRequest<Resource[]>({
      type: paramCase(desc.name)
    })
  }

  delete<I extends Resource>(instance: I): Promise<boolean> {
    const url =
      this.url + "/" +
      paramCase(this.entityDescription.name) + "/" +
      instance.id

    const init = {
      method: "DELETE"
    } as RequestInit

    return fetch(url, init).then(r => r.ok)
  }


  queryRequest<T>(request: Request): Promise<T> {
    const url = this.url + "/" + request.type
    return fetch(url).then(r => {
      console.info("queryRequest", r)
      if (!r.ok)
        throw Error(r.statusText)
      return r.json()
    })
  }

  request<T>(request: Request): Promise<T> {
    const body = this.jsonBody(request.body)
    const url = this.url + "/" + request.type
    const init = {
      method: "POST",
      body
    } as RequestInit

    return fetch(url, init).then(r => r.json())
  }

  jsonBody(body?: any): string | undefined {
    if (body)
      return JSON.stringify(body)
    return undefined
  }
}

export const API = <I extends Resource>(desc: EntityDescription) =>
  new Api("http://localhost:8000/api/platform", desc)
