import React, { useState } from "react"
import { Resource } from "../../Resource"
import { EuiButton, EuiButtonProps } from "@elastic/eui"
import { API } from "../../api/Api"
import { EntityDescription } from "../../descriptions"


interface SaveButtonProps<T extends Resource> {
  buttonProps?: EuiButtonProps
  instance: T
  entityDescription: EntityDescription
  saveAction?: (instance: T) => Promise<T>
  afterSave?: (instance: T) => void
}

const SaveButton = <T extends Resource>(props: SaveButtonProps<T>) => {
  const { afterSave, buttonProps, instance, saveAction, entityDescription } = props

  const [isLoading, setLoading] = useState(false)

  const close = (item: T) => {
    console.info("SaveButton close")
    setLoading(false)
    if (afterSave)
      afterSave(item)
    return item
  }

  const saveItem = (item: T) => {
    setLoading(true)
    const api = API(entityDescription)
    const save = saveAction ? saveAction : api.update.bind(api)
    return save(item).then(close)
  }
  /*const saveItem: (item: Instance<T>) => Promise<T> = (saveAction
    ? (item: Instance<T>) => saveAction(item).then(close)
    : (item: Instance<T>) => API.save(item).then(close)
  )*/

  return (
    <EuiButton
      fill
      fullWidth
      iconType={"save"}
      isLoading={isLoading}
      onClick={() => saveItem(instance)}
      {...buttonProps}
    >
      Save
    </EuiButton>
  )
}

export default SaveButton
