import React, { useState } from "react"
import { Resource } from "../../Resource"
import { EntityDescription } from "../../descriptions"
import { EuiButton, EuiButtonProps } from "@elastic/eui"
import EntityFlyout from "../EntityFlyout"
import { API } from "../../api/Api"

interface AddEntityProps<T extends Resource> {
  entityDesc: EntityDescription
  onAdd?: (item: T) => T
  buttonProps?: EuiButtonProps
  addAction?: (item: T) => Promise<T>
}

function AddEntity<T extends Resource>(props: AddEntityProps<T>): React.ReactElement<AddEntityProps<T>> {
  const { addAction, onAdd, entityDesc, buttonProps } = props

  const [isOpen, setOpen] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [newItem, setNewItem] = useState<T>(new Resource() as T)
  const onClose = () => setOpen(false)
  const open = () => {
    setNewItem(new Resource() as T)
    setOpen(true)
  }

  const close = (item: T) => {
    setLoading(false)
    setOpen(false)
    return item
  }

  const saveItem: (item: T) => Promise<T> = (addAction
    ? (item: T) => addAction(item).then(onAdd).then(close)
    : (item: T) => API(entityDesc).create(item).then(onAdd).then(close)
  )

  return (
    <>
      <EuiButton
        isSelected={isOpen}
        onClick={open}
        fill={isOpen}
        {...buttonProps}
      >
        Add {entityDesc.name}
      </EuiButton>

      <EntityFlyout
        headerTitleAction={"Add"}
        isOpen={isOpen}
        onClose={onClose}
        entityDesc={entityDesc}
        resource={newItem}
        okButton={
          <EuiButton
            isLoading={isLoading}
            onClick={() => saveItem(newItem)}
            iconType={"plus"}
            color={"primary"}
            fullWidth
            fill
          >
            Add
          </EuiButton>
        }
      />
    </>
  )
}

export default AddEntity
