export const PRIMITIVE = "PRIMITIVE"
export const ENUM = "ENUM"
export const REFERENCE_FROM = "REFERENCE_FROM" // href by id to another external entity
export const REFERENCE_TO = "REFERENCE_TO" //
export const ENTITY = "ENTITY" // some embedded entity, which could not be referenced as self entity
export const COLLECTION = "COLLECTION"
export const COMPOSITION = "COMPOSITION"

export type TypeCategory =
  typeof PRIMITIVE |
  typeof ENUM |
  typeof REFERENCE_FROM |
  typeof REFERENCE_TO |
  typeof ENTITY |
  typeof COLLECTION |
  typeof COMPOSITION

export const INT = "INT"
export const LONG = "LONG"
export const STRING = "STRING"
export const BOOL = "BOOL"
export const TEXT = "TEXT"
export const LINK = "LINK"
export const PASSWORD = "PASSWORD"
export const NUMBER = "NUMBER"
export const DATE = "DATE"
export const TIME = "TIME"
export const DATETIME = "DATETIME"
export const FILE = "FILE"

export type PrimitiveType =
  typeof INT |
  typeof LONG |
  typeof STRING |
  typeof BOOL |
  typeof TEXT |
  typeof LINK |
  typeof PASSWORD |
  typeof NUMBER |
  typeof DATE |
  typeof TIME |
  typeof DATETIME

export class Enum {
  constructor(
    public name: string,
    public values: EnumValue[]) {
  }
}

export class EnumValue {
  constructor(
    public ordinal: number,
    public key: string,
    public name: string) {
  }
}


export interface Field {
  name: string
}

export class PrimitiveField implements Field {
  constructor(
    public name: string,
    public primitiveType: PrimitiveType) {
  }
}

export class EntitiesCollectionField implements Field {
  constructor(
    public name: string,
    public desc: EntityDescription) {
  }
}

export class EnumField implements Field {
  constructor(
    public name: string,
    public eenum: Enum) {
  }
}

export class ReferenceField implements Field {
  constructor(
    public name: string,
    public desc: EntityDescription,
    public isCollection: boolean) {
  }
}

export class EntityDescription {
  constructor(
    public name: string,
    public primitiveFields: PrimitiveField[] = [],
    public entitiesCollectionFields: EntitiesCollectionField[] = [],
    public enumFields: EnumField[] = [],
    public referenceFields: ReferenceField[] = []) {
  }
}


export interface EntityDescProps {
  entityDesc: EntityDescription
}
