import { EntityDescription, EnumField, PrimitiveField, STRING } from "../../../resources/descriptions"
import { PRIMITIVE_TYPE_ENUM } from "../TypeEnums"

export const PrimitiveFieldNameField = new PrimitiveField("Name", STRING)
export const PrimitiveFieldEnumFieldField = new EnumField("Primitive type", PRIMITIVE_TYPE_ENUM)

const PrimitiveFieldDescription = new EntityDescription("Primitive field",
  [
    PrimitiveFieldNameField
  ],
  [],
  [
    PrimitiveFieldEnumFieldField
  ],
  []
)

export default PrimitiveFieldDescription
