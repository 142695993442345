import React, { useState } from "react"
import { Resource } from "../../Resource"
import { EntityDescription } from "../../descriptions"
import { API } from "../../api/Api"
import { EuiButton, EuiButtonIcon } from "@elastic/eui"
import EntityFlyout from "../EntityFlyout"
import onClick from "../onClick"

interface EditEntityProps<T extends Resource> {
  buttonType: "icon" | "button"
  entityDesc: EntityDescription
  instance: T
  editAction?: (instance: T) => Promise<T>
  onEdit?: (item: T) => void
}

function EditEntity<T extends Resource>(props: EditEntityProps<T>): React.ReactElement<EditEntityProps<T>> {
  const { buttonType, editAction, entityDesc, instance, onEdit } = props

  const [isOpen, setOpen] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const onClose = () => setOpen(false)
  const open = () => setOpen(true)

  const close = (item: T) => {
    if (onEdit)
      onEdit(item)
    setLoading(false)
    setOpen(false)
    return item
  }

  const saveItem: (item: T) => Promise<T> = (editAction
      ? (item: T) => editAction(item).then(close)
      : (item: T) => API(entityDesc).update(item).then(close)
  )

  return (
    <>
      {/* Just regular button */}
      {
        buttonType === "button" &&
        <EuiButton onClick={onClick(open)} isSelected={isOpen} fill={isOpen}>
          Edit {entityDesc.name}
        </EuiButton>
      }

      {/* Icon button */}
      {
        buttonType === "icon" &&
        <EuiButtonIcon
          iconType={"pencil"}
          onClick={onClick(open)}
          isSelected={isOpen}
          display={isOpen ? "fill" : "empty"}
          aria-label={"Edit"} />
      }

      <EntityFlyout
        headerTitleAction={"Edit"}
        entityDesc={entityDesc}
        resource={instance}
        onClose={onClose}
        isOpen={isOpen}
        okButton={
          <EuiButton fill fullWidth iconType={"save"} isLoading={isLoading} onClick={() => saveItem(instance)}>
            Save
          </EuiButton>
        }
      />
    </>
  )
}

export default EditEntity
