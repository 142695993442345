import React, { useState } from "react"
import {
  DATE,
  EntitiesCollectionField,
  EntityDescription,
  EnumField,
  Field,
  LINK,
  NUMBER,
  PASSWORD,
  PrimitiveField,
  STRING,
  TEXT
} from "../descriptions"
import {
  EuiDatePicker,
  EuiFieldNumber,
  EuiFieldPassword,
  EuiFieldText,
  EuiForm,
  EuiFormRow,
  EuiSelect,
  EuiTextArea
} from "@elastic/eui"
import { sentenceCase } from "change-case"
import { getResourceValue, Resource, setResourceValue } from "../Resource"
import moment from "moment"
import EntityTable from "./EntityTable"
import AddEntity from "./actions/AddEntity"
import { Action } from "@elastic/eui/src/components/basic_table/action_types"
import EditEntity from "./actions/EditEntity"
import DeleteEntity from "./actions/DeleteEntity"


function StringField(props: FormFieldProps<PrimitiveField>): React.ReactElement<FormFieldProps<PrimitiveField>> {
  const {field, resource} = props
  const [value, setValue] = useState(getResourceValue(resource, field) || "")

  const onChange = (e: any) => {
    const value = e.target.value
    setResourceValue(resource, field, value)
    setValue(value || "")
  }

  return <EuiFieldText
    value={value}
    onChange={onChange}
    name={field.name}
    fullWidth
    compressed
  />
}

function TextField(props: FormFieldProps<PrimitiveField>): React.ReactElement<FormFieldProps<PrimitiveField>> {
  const {field, resource} = props
  const [value, setValue] = useState(getResourceValue(resource, field) || "")

  const onChange = (e: any) => {
    const value = e.target.value
    setResourceValue(resource, field, value)
    setValue(value || "")
  }

  return <EuiTextArea
    value={value}
    onChange={onChange}
    name={field.name}
    rows={2}
    fullWidth
    compressed
  />
}

function NumberField(props: FormFieldProps<PrimitiveField>): React.ReactElement<FormFieldProps<PrimitiveField>> {
  const {field, resource} = props
  const [value, setValue] = useState(getResourceValue(resource, field) || "")

  const onChange = (e: any) => {
    const value = e.target.value
    setResourceValue(resource, field, value)
    setValue(value || "")
  }

  return <EuiFieldNumber
    value={value}
    onChange={onChange}
    name={field.name}
    fullWidth
  />
}

function PasswordField(props: FormFieldProps<PrimitiveField>): React.ReactElement<FormFieldProps<PrimitiveField>> {
  const {field, resource} = props
  const [value, setValue] = useState(getResourceValue(resource, field))

  const onChange = (e: any) => {
    const value = e.target.value
    setResourceValue(resource, field, value)
    setValue(value)
  }

  return <EuiFieldPassword
    value={value}
    onChange={onChange}
    name={field.name}
    fullWidth
  />
}

function DateField(props: FormFieldProps<PrimitiveField>): React.ReactElement<FormFieldProps<PrimitiveField>> {
  const {field, resource} = props
  const value = getResourceValue(resource, field)
  const [momentValue, setMomentValue] = useState(value ? moment(value as Date) : undefined)

  const onChange = (date: moment.Moment) => {
    setMomentValue(date)
    setResourceValue(resource, field, date?.toDate())
  }

  return <EuiDatePicker
    selected={momentValue}
    onChange={onChange}
    name={field.name}
    fullWidth
  />
}


function EnumFieldComponent(props: FormFieldProps<EnumField>): React.ReactElement<FormFieldProps<EnumField>> {
  const { field, resource } = props
  const enumValues = field.eenum.values
  const [value, setValue] = useState(getResourceValue(resource, field))

  const options = enumValues.map(it => ({
    value: it.key,
    text: sentenceCase(it.key)
  })) || []

  const onChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newValue = enumValues.find(it => it.key === e.target.value)!!
    setResourceValue(resource, field, newValue)
    setValue(newValue.key)
  }

  return <EuiSelect
    value={value}
    options={options}
    onChange={onChange}
    name={field.name}
    fullWidth
  />
}

/*function EntityField<I extends Resource>(props: FormFieldProps<I>): React.ReactElement<FormFieldProps<I>> {
  const { fieldDesc, resource } = props
  const [value] = useState(getResourceValue(resource, fieldDesc) || new Resource())
  return <EntityForm entityDesc={fieldDesc.type.entityDesc!!} resource={value} />
}*/

function useForceUpdate() {
  const [, setValue] = useState(0); // integer state
  return () => setValue(value => value + 1); // update the state to force render
}

function CollectionField(props: FormFieldProps<EntitiesCollectionField>): React.ReactElement<FormFieldProps<EntitiesCollectionField>> {
  const {field, resource} = props
  const entityDesc = field.desc

  const [instanceItems, setInstanceItems] = useState<Resource[]>(getResourceValue(resource, field) || [])
  const [items, setItems] = useState<Resource[]>(instanceItems)
  const forceUpdate = useForceUpdate()

  const addInstance = (resource: Resource) => {
    return Promise.resolve().then(() => {
      const updatedInstanceItems = ([...instanceItems, resource]) as Resource[]
      setResourceValue(resource, field, updatedInstanceItems)
      setInstanceItems(updatedInstanceItems)
      setItems(updatedInstanceItems)
      return resource
    })
  }

  const customActions: Action<Resource>[] = [
    {
      render: (resource: Resource) => (
        <EditEntity
          buttonType={"icon"}
          entityDesc={entityDesc}
          instance={resource}
          editAction={(item) => Promise.resolve().then(() => {
            forceUpdate()
            return item
          })}
        />
      )
    },
    {
      render: (resource: Resource) => (
        <DeleteEntity
          buttonType={"icon"}
          entityDesc={entityDesc}
          instance={resource}
          deleteAction={(item) => {
            return Promise.resolve().then(() => {
              const updatedInstanceItems = instanceItems.filter(it => it.id !== item.id)
              setResourceValue(resource, field, updatedInstanceItems)
              setInstanceItems(updatedInstanceItems)
              setItems(updatedInstanceItems)
            })
          }}
        />
      )
    }
  ]

  return (
    <div>
      <AddEntity
        addAction={addInstance}
        entityDesc={entityDesc}
        buttonProps={{
          size: "s",
          fullWidth: true,
          color: "success",
          iconType: "plus"
        }} />
      <EntityTable
        entityDesc={entityDesc}
        loading={false}
        items={items}
        customActions={customActions}
      />
    </div>
  )
}


interface FormFieldProps<I extends Field> {
  field: I
  resource: Resource
}

const row = (field: Field, el: React.ReactElement) =>
  <EuiFormRow label={sentenceCase(field.name)} fullWidth>
    {el}
  </EuiFormRow>

function PrimitiveFormField<I extends Resource>(props: FormFieldProps<PrimitiveField>): React.ReactElement<FormFieldProps<PrimitiveField>> {
  const field = props.field
  const type = field.primitiveType

  /*const type = field.type

  if (type.category === ENTITY) {
    return (
      <EuiFormRow fullWidth helpText={field.description}>
        <EuiAccordion
          id={`entity${props.instance.id}`}
          arrowDisplay={"right"}
          buttonContent={
            sentenceCase(field.name)
          }>
          <EuiPanel borderRadius={"none"} hasBorder={false} hasShadow={false} style={{ borderLeft: "0.5px solid", marginLeft: "0.2rem", marginTop: "0.2rem" }}>
            <EntityField {...props} />
          </EuiPanel>
        </EuiAccordion>
      </EuiFormRow>
    )
  }

  if (type.category === COLLECTION) {
    return row(
      <CollectionField {...props} />
    )
  }

  if (type.category === REFERENCE_TO) {
    return row(
      <CollectionField {...props} />
    )
  }

  if (type.category === ENUM) {
    return row(
      <EnumField {...props} />
    )
  }*/

  let fieldEl: React.ReactElement
  switch (type) {
    case TEXT:
      fieldEl = <TextField {...props} />
      break
    case STRING:
    case LINK:
      fieldEl = <StringField {...props} />
      break
    case NUMBER:
      fieldEl = <NumberField {...props} />
      break
    case PASSWORD:
      fieldEl = <PasswordField {...props} />
      break
    case DATE:
      fieldEl = <DateField {...props} />
      break
    default:
      throw Error(`No field render available for field type "${type}"`)
  }

  return row(field, fieldEl)
}


interface EntityFormProps {
  entityDesc: EntityDescription
  resource: Resource
}

function EntityForm(props: EntityFormProps): React.ReactElement<EntityFormProps> {
  const entity = props.entityDesc
  return (
    <EuiForm component={"form"}>
      {entity.primitiveFields.map(e =>
        <PrimitiveFormField key={e.name} field={e} resource={props.resource}/>
      )}
      {entity.entitiesCollectionFields.map(field =>
        row(field, <CollectionField field={field} resource={props.resource} />)
      )}
    </EuiForm>
  )
}

export default EntityForm
