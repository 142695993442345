import { EntityDescription, PrimitiveField, ReferenceField, STRING } from "../../../resources/descriptions"
import EnumDescription from "./EnumDescription"
import EntityDescriptionDesc from "./EntityDesc"

export const DataModelNameField = new PrimitiveField("Name", STRING)
export const DataModelEnumsField = new ReferenceField("Enums", EnumDescription, true)
export const DataModelEntitiesField = new ReferenceField("Entities", EntityDescriptionDesc, true)

const DataModelDesc = new EntityDescription("Data model",
  [
    DataModelNameField
  ],
  [],
  [],
  [
    DataModelEnumsField,
    DataModelEntitiesField
  ]
)

export default DataModelDesc
