import { Enum, EnumValue } from "../../resources/descriptions"

export const ENTITY_TYPE_CATEGORY_ENUM = new Enum("Field type", [
  new EnumValue(1, "PRIMITIVE", "Primitive"),
  new EnumValue(2, "ENUM", "Enum"),
  new EnumValue(3, "REFERENCE_FROM", "Reference from"),
  new EnumValue(4, "REFERENCE_TO", "Reference to"),
  new EnumValue(5, "ENTITY", "Entity"),
  new EnumValue(6, "COLLECTION", "Collection"),
  new EnumValue(7, "COMPOSITION", "Composition"),
])

export const PRIMITIVE_TYPE_ENUM = new Enum("primitive type", [
  new EnumValue(1, "STRING", "String"),
  new EnumValue(2, "TEXT", "Text"),
  new EnumValue(3, "LINK", "Link"),
  new EnumValue(4, "PASSWORD", "Password"),
  new EnumValue(5, "NUMBER", "Number"),
  new EnumValue(6, "DATE", "Date"),
  new EnumValue(7, "TIME", "Time"),
  new EnumValue(8, "DATETIME", "Datetime"),
])
