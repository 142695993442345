import { EntityDescription, PrimitiveField, ReferenceField, STRING } from "../../../resources/descriptions"
import EnumValueDescription from "./EnumValueDescription"

export const EnumNameField = new PrimitiveField("name", STRING)
export const EnumValuesField = new ReferenceField("values", EnumValueDescription, true)

const EnumDescription =
  new EntityDescription("Enum",
    [
      EnumNameField
    ],
    [],
    [],
    [
      EnumValuesField
    ])

export default EnumDescription
