import { EntitiesCollectionField, EntityDescription, PrimitiveField, STRING } from "../../../resources/descriptions"
import EnumValueDescription from "./EnumValueDescription"

export const EnumFieldNameField = new PrimitiveField("Name", STRING)
export const EnumFieldValuesField = new EntitiesCollectionField("Values", EnumValueDescription)

const EnumFieldDescription = new EntityDescription("Enum field",
  [
    EnumFieldNameField
  ],
  [
    EnumFieldValuesField
  ],
  [],
  []
)

export default EnumFieldDescription
