import React, { useState } from "react"
import { EuiHeaderLink, slugify } from "@elastic/eui"
import { Link, Navigate, Route, Routes, useLocation, useRoutes } from "react-router-dom"
import EntityPanel from "../resources/components/EntityPanel"
import { EuiSideNavItemType } from "@elastic/eui/src/components/side_nav/side_nav_types"
import DataModelDesc, { DataModelEnumsField } from "./entities/data-model/DataModelDescription"
import "./Application.sass"
import EnumDescription from "./entities/data-model/EnumDescription"
import EntityDescriptionDesc from "./entities/data-model/EntityDesc"

const Application = () => {
  useRoutes([

  ])

  const [selectedItemName, setSelectedItem] = useState('Time stuff');
  const selectItem = (name: string) => {
    setSelectedItem(name);
  };

  const createItem = (name: string, data = {}) => {
    // NOTE: Duplicate `name` values will cause `id` collisions.
    return {
      id: slugify(name),
      name,
      isSelected: selectedItemName === name,
      onClick: () => selectItem(name),
      ...data,
    } as EuiSideNavItemType<any>;
  };

  // eslint-disable-next-line
  const sideNav = [
    createItem('Admin', {
      onClick: undefined,
      items: [
        createItem('Pages'),
        createItem('Navigation'),
        createItem('Use cases'),
        createItem('Entities'),
        createItem('Types'),
      ],
    }),
  ]

  const location = useLocation()

  const link = (path: string, name: string, icon?: string) =>
    <Link to={path}>
      <EuiHeaderLink
        isActive={path === location.pathname}
        iconType={icon}>
        {name}
      </EuiHeaderLink>
    </Link>

  /*return (
    <div className={"application"}>
      <Index />
    </div>
  )*/

  return (
    <div className={"application"}>
      {/*<EuiHeader position={"static"} sections={[
        {
          items: [
            <EuiHeaderLogo iconType={"heatmap"}>var a</EuiHeaderLogo>
          ],
          borders: "none"
        },
        {
          items: [
            <EuiHeaderLinks>
              {link("/hosts", "Hosts", HostDesc.icon)}
              {link("/projects", "Projects")}
              {link("/apis", "Apis", "globe-network")}
              {link("/data-models", "Data models", "many-to-many")}
              {link("/meta", "Meta", "user")}
              {link("/persons", "Persons", PersonDesc.icon)}
              {link("/entities", "Entities", EEntityDescDesc.icon)}
              {link("/types", "Types")}
            </EuiHeaderLinks>
          ],
          borders: "none"
        }
      ]} />*/}

      <Routes>
        <Route path={"/data-models"} element={
          <EntityPanel entityDesc={DataModelDesc} hiddenTableFields={[DataModelEnumsField]} />}
        />
        <Route path={"/enums"} element={<EntityPanel entityDesc={EnumDescription} />} />
        <Route path={"/entities"} element={<EntityPanel entityDesc={EntityDescriptionDesc} />} />

        {/*<Route path={"/meta"}><Meta /></Route>
        <Route path={"/persons"}><EntityPanel entityDesc={PersonDesc} /></Route>*/}

        {/*<Route path={"/entities"}><EntityPanel entityDesc={EEntityDescDesc} hiddenTableFields={[EEntityDescFieldDescField]}/></Route>
        <Route path={"/types"}><EntityPanel entityDesc={TTypeDesc()} hiddenTableFields={[TTypeEntityDescField, TTypeEnumValuesField]}/></Route>*/}

        <Route element={<Navigate to={"/hosts"} />} />
      </Routes>
    </div>

  )
}

export default Application
