import React, { useEffect, useState } from "react"
import { API } from "../api/Api"
import { EuiPage, EuiPageBody, EuiPageContent, EuiPageContentBody, EuiPageHeader } from "@elastic/eui"
import EntityTable from "./EntityTable"
import { Resource } from "../Resource"
import { EntityDescription, Field } from "../descriptions"
import { sentenceCase } from "change-case"
import AddEntity from "./actions/AddEntity"

interface EntityPanelProps {
  entityDesc: EntityDescription
  hiddenTableFields?: Field[]
}

function EntityPanel(props: EntityPanelProps): React.ReactElement<EntityPanelProps> {
  const { entityDesc, hiddenTableFields } = props

  const [loading, setLoading] = useState(true)
  const [items, setItems] = useState<Resource[]>([])

  const addItem = (item: Resource) => {
    setItems([item, ...items])
    return item
  }

  const deleteItem = (item: Resource) => {
    setItems(items.filter(it => it.id !== item.id))
  }

  const onEdit = (item: Resource) => {
    setItems(oldItems => [...oldItems])
  }

  useEffect(() => {
    const handle = (items?: Resource[]) => {
      setItems(items || [])
      setLoading(false)
    }

    API(entityDesc).list(entityDesc).then(handle).catch((err: any) => {
      handle([])
      // throw err
    })
  }, [entityDesc])

  return (
    <EuiPage paddingSize={"none"}>

      <EuiPageBody panelled>
        <EuiPageHeader
          pageTitle={sentenceCase(entityDesc.name)}
          rightSideItems={[
            <AddEntity entityDesc={entityDesc} onAdd={addItem} />
          ]}
        />

        {/*<EuiFlexGroup alignItems="center">
        <EuiFlexItem grow={false}>

        </EuiFlexItem>
        <EuiFlexItem />
      </EuiFlexGroup>*/}

        {/*<EuiSpacer size="l" />*/}

        <EuiPageContent
          hasBorder={false}
          hasShadow={false}
          paddingSize="none"
          color="transparent"
          borderRadius="none">
          <EuiPageContentBody>
            <EntityTable
              entityDesc={entityDesc}
              loading={loading}
              items={items}
              hiddenFields={hiddenTableFields}
              onEdit={onEdit}
              onDelete={deleteItem} />
          </EuiPageContentBody>
        </EuiPageContent>
      </EuiPageBody>
    </EuiPage>
  )
}

export default EntityPanel
