import {
  EntitiesCollectionField,
  EntityDescription,
  PrimitiveField,
  ReferenceField,
  STRING
} from "../../../resources/descriptions"
import EntityDescriptionDesc from "./EntityDesc"

export const EntitiesCollectionFieldNameField = new PrimitiveField("Name", STRING)
export const EntitiesCollectionFieldDescField = new ReferenceField("Desc", EntityDescriptionDesc, false)

const EntitiesCollectionFieldDescription = new EntityDescription("Entities collection field",
  [
    EntitiesCollectionFieldNameField
  ],
  [],
  [],
  [
    EntitiesCollectionFieldDescField
  ]
)

export default EntitiesCollectionFieldDescription

export const EntityDescriptionEntitiesCollectionFieldsField = new EntitiesCollectionField("Entities collection fields", EntitiesCollectionFieldDescription)
