import React, { useState } from "react"
import { EuiBasicTable, EuiLink } from "@elastic/eui"
import { Resource } from "../Resource"
import { EntityDescription, Field, LINK } from "../descriptions"
import { camelCase, sentenceCase } from "change-case"
import { EuiBasicTableColumn } from "@elastic/eui/src/components/basic_table/basic_table"
import { Action } from "@elastic/eui/src/components/basic_table/action_types"
import EditEntity from "./actions/EditEntity"
import DeleteEntity from "./actions/DeleteEntity"
import EntityFlyout, { useEntityFlyout } from "./EntityFlyout"
import SaveButton from "./actions/SaveButton"


function euiColumns(
  entityDesc: EntityDescription,
  hiddenFields?: Field[],
  customActions?: Action<Resource>[],
  onEdit?: (resource: Resource) => void,
  onDelete?: (resource: Resource) => void
): EuiBasicTableColumn<Resource>[] {

  // todo: maybe set any other from primitive fields
  const columns =  entityDesc.primitiveFields
    .filter(field => !((hiddenFields || []) as Field[]).find(it => it === field))
    .map(field => {
      const render =
        field.primitiveType === LINK
          ? (name: any) => <EuiLink href={name} target="_blank">{name}</EuiLink>
          : undefined

      return {
        field: camelCase(field.name),
        name: sentenceCase(field.name),
        render
      } as EuiBasicTableColumn<Resource>
    })

  const defaultActions = [
    {
      render: (item: Resource) => (
        <EditEntity
          buttonType={"icon"}
          entityDesc={entityDesc}
          instance={item}
          onEdit={onEdit}
        />
      )
    },
    {
      render: (item: Resource) => (
        <DeleteEntity
          buttonType={"icon"}
          entityDesc={entityDesc}
          instance={item}
          onDelete={onDelete}
        />
      )
    }
  ]

  columns.push({
    name: "Actions",
    actions: customActions ? customActions : defaultActions
  })

  return columns
}


interface EntityTableProps {
  entityDesc: EntityDescription
  loading: boolean
  items: Resource[]
  hiddenFields?: Field[]
  customActions?: Action<Resource>[]
  onEdit?: (item: Resource) => void
  onDelete?: (item: Resource) => void
}

function EntityTable(props: EntityTableProps): React.ReactElement<EntityTableProps> {
  const { customActions, entityDesc, items, hiddenFields, loading, onEdit, onDelete } = props
  const columns = euiColumns(entityDesc, hiddenFields, customActions, onEdit, onDelete)

  const [selectedItem, setSelectedItem] = useState<Resource>()
  const { isOpen, close } = useEntityFlyout()
  const onClose = (item: Resource) => {
    if (onEdit)
      onEdit(item)
    setSelectedItem(undefined)
    close()
  }

  console.info("EntityTable", props, columns)

  return (
    <>
      <EuiBasicTable
        tableLayout={"auto"}
        loading={loading}
        items={items}
        columns={columns}
        hasActions
      />
      {
        selectedItem &&
        <EntityFlyout
          headerTitleAction={"Edit"}
          entityDesc={entityDesc}
          resource={selectedItem!!}
          onClose={onClose}
          isOpen={isOpen}
          okButton={
            <SaveButton
              instance={selectedItem!!}
              afterSave={onClose}
              entityDescription={entityDesc}
            />
          }
        />
      }
    </>
  )
}

export default EntityTable
