import {
  EntitiesCollectionField,
  EntityDescription,
  PrimitiveField,
  ReferenceField,
  STRING
} from "../../../resources/descriptions"
import EntityDescriptionDesc from "./EntityDesc"

export const ReferenceFieldNameField = new PrimitiveField("Name", STRING)
export const ReferenceFieldDescField = new ReferenceField("Desc", EntityDescriptionDesc, false)

const ReferenceFieldDescription = new EntityDescription("Reference field",
  [
    ReferenceFieldNameField
  ],
  [],
  [],
  [
    ReferenceFieldDescField
  ]
)

export default ReferenceFieldDescription

export const EntityDescriptionReferenceFieldsField = new EntitiesCollectionField("Reference fields", ReferenceFieldDescription)
