import React, { useState } from "react"
import { Resource } from "../../Resource"
import { EntityDescription } from "../../descriptions"
import { API } from "../../api/Api"
import { EuiButton, EuiButtonIcon, EuiButtonProps } from "@elastic/eui"
import onClick from "../onClick"


interface DeleteEntityProps<T extends Resource> {
  buttonProps?: EuiButtonProps
  buttonType: "icon" | "button"
  deleteAction?: (item: T) => Promise<void>
  entityDesc: EntityDescription
  instance: T
  onDelete?: (item: T) => void
}

function DeleteEntity<T extends Resource>(props: DeleteEntityProps<T>): React.ReactElement<DeleteEntityProps<T>> {
  const { buttonProps, buttonType, deleteAction, instance, entityDesc, onDelete } = props

  const [isLoading, setLoading] = useState(false)

  const finish = (item: T) => {
    setLoading(false)
    return item
  }

  const onDeleteAction = () => {
    if (onDelete)
      onDelete(instance)
  }

  const deleteItem: (item: T) => void = (
    deleteAction
      ? (item: T) => deleteAction(item).then(onDeleteAction).then(() => finish(item))
      : (item: T) => API(entityDesc).delete(item).then(onDeleteAction).then(() => finish(item))
  )

  return (
    <>
      {/* Just regular button */}
      {
        buttonType === "button" &&
        <EuiButton
          onClick={onClick(() => deleteItem(instance))}
          fill={isLoading}
          iconType={"trash"}
          color={"danger"}
          isLoading={isLoading} {...buttonProps}>
          Delete
        </EuiButton>
      }

      {/* Icon button */}
      {
        buttonType === "icon" &&
        <EuiButtonIcon
          iconType={"trash"}
          onClick={() => deleteItem(instance)}
          isSelected={isLoading}
          color={"danger"}
          display={isLoading ? "fill" : "empty"}
          aria-label={"Delete"} />
      }
    </>
  )
}

export default DeleteEntity
